<template>
  <b-container>
    <b-row>
      <b-col cols="12" class="blog-post__back mb-3">
        <router-link to="/">
          {{ $t('Blog_BlogPost_btn2back') }}
        </router-link>
      </b-col>
    </b-row>

    <b-row style="background: white" class="p-4">
      <b-col order="1" order-lg="0">
        <general v-bind="product"/>
        <div v-if="product.info" class="product-modal__general-composition">
          {{ $t('Modals_Product_General_condition') }}
          <p>{{ product.info }}</p>
        </div>
      </b-col>
      <b-col order="0" order-lg="1">
        <images
          :can_decor="product.can_decor"
          :images="product.images"
        />
      </b-col>
    </b-row>

    <b-row style="background: white; overflow: hidden" class="p-4">
      <b-col cols="12" lg="6">
<!--        <reviews-->
<!--          :count="product.reviews_count"-->
<!--          :reviews="product.reviews"-->
<!--          :rating="product.rating"-->
<!--        />-->
        <nutrition white v-bind="product"/>
      </b-col>
      <b-col>
        <stock />
      </b-col>
    </b-row>

    <b-row v-if="product.marking" style="background: white" class="p-4">
      <b-col cols="12" class="">
        <div class="help-accordion q-card">
          <div class="accordion" role="tablist">
            <div class="help-accordion__tab">
              <div
                class="help-accordion__header"
                v-b-toggle.accordion-5
              >
                Дополнительная информация / Қосымша ақпарат
              </div>
              <b-collapse
                id="accordion-5"
                accordion="my-accordion"
                role="tabpanel"
                visible
              >
                <div class="product-modal__nutrition-items">
                  <div
                    class="product-modal__nutrition-item"
                  >
                    <div class="product-modal__nutrition-item-key">
                      Сақтау мерзімі және шарттары / Срок годности и условия хранения
                    </div>
                    <div class="product-modal__nutrition-item-value">
                      {{ product.marking.conditions }}
                    </div>
                  </div>

                  <div
                      class="product-modal__nutrition-item"
                  >
                    <div class="product-modal__nutrition-item-key">
                      Калорийность
                    </div>
                    <div class="product-modal__nutrition-item-value">
                      {{ product.energy.energy_full_amount }} кДж
                    </div>
                  </div>

                  <div
                    class="product-modal__nutrition-item"
                  >
                    <div class="product-modal__nutrition-item-key">
                      Қолдану туралы ұсыныстар мен шектеулер / Рекомендации и ограничения по использованию
                    </div>
                    <div class="product-modal__nutrition-item-value">
                      {{ product.marking.restrictions }}
                    </div>
                  </div>

                  <div
                    class="product-modal__nutrition-item"
                  >
                    <div class="product-modal__nutrition-item-key">
                      Өндіруші / Изготовитель
                    </div>
                    <div class="product-modal__nutrition-item-value">
                      {{ product.marking.manufacturer }}
                    </div>
                  </div>

                  <div
                    class="product-modal__nutrition-item"
                  >
                    <div class="product-modal__nutrition-item-key">
                      Тұтынушылардың шағымдары / Претензии от потребителей
                    </div>
                    <div class="product-modal__nutrition-item-value" v-html="product.marking.contacts_for_claims">
                    </div>
                  </div>

                  <div
                    class="product-modal__nutrition-item"
                  >
                    <div class="product-modal__nutrition-item-key">
                      Қаптамадағы белгілер / Знаки на упаковке
                    </div>
                    <div class="product-modal__nutrition-item-value">
                      <div class="marking-images">
                        <img v-for="img in product.marking.images" :src="img.img" alt="img">
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapState} from "vuex";
import Images from "@/components/Modals/Product/Images.vue";
import General from "@/components/Modals/Product/General.vue";
import Reviews from "@/components/Modals/Product/Reviews.vue";
import Nutrition from "@/components/Modals/Product/Nutrition.vue";
import Stock from "@/components/Modals/Product/Stock.vue";

export default {
  components: {Stock, Nutrition, Reviews, General, Images},
  computed: {
    ...mapState({
      product: state => state.products.modalProduct,
    }),
    productId() {
      return this.$route.params.id
    },
  },

  mounted() {
    this.getProduct()
  },

  methods: {
    async getProduct() {
      await this.$store.dispatch('products/getProduct', this.productId);
    }
  }
}

</script>

<style scoped>
.product-modal__nutrition-items {
  width: 100%;
}
.product-modal__nutrition-item-key {
  width: 100%;
}

.marking-images {
  img {
    width: 50px;
  }
}
</style>